/* You can add global styles to this file, and also import other style files */

@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@200;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sarabun:wght@100;200;300;400;500;600&display=swap");
@import '~@angular/cdk/overlay-prebuilt.css';
//@import '~ngx-toastr/toastr.css';
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import 'tippy.js/dist/tippy.css';
@import 'tippy.js/animations/scale.css';
@import 'tippy.js/themes/light.css';
.datepicker table tr td.active {
  background-color: #2bc381 !important;
  border-color: green !important;
  color: #fff !important;
}

.ng-dropdown-panel {
  z-index: 9000 !important;
}

body {
  position: relative;
}

// Support animate.css modal
.modal.fade .modal-dialog {
  -webkit-transform: translate(0);
  -moz-transform: translate(0);
  transform: translate(0);
}

a {
  cursor: pointer;
}

.swal2-styled.swal2-confirm {
  background-color: #be2d2d;
}

.txt-red {
  color: red;
}

.txt-orange {
  color: #ff94008c;
}

.txt-green {
  color: #28C482;
}

.invalid {
  border: 1px solid red;
  border-radius: 4px;
  color: red;
}

.hr-solid {
  border-bottom: 1px solid #e4e4e4 !important;
  margin: 0 !important;
  margin-top: 0.5rem !important;
}

.bg-4c {
  background-color: #4c4c4c !important;
}

.panel.panel-fullscreen {
  z-index: 2000 !important;
}

.remark {
  background-color: #e5cb758c;
  border: 2px solid #ff94008c;
  border-radius: 0.5rem;
}

.table-action {
  width: 70px !important;
}

.modal {
  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0px #545454c2;
    border-radius: 15px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #93a39e;
    border-radius: 15px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #859891;
  }
}

.modal-body {
  max-height: 77vh !important;
  overflow-y: auto !important;
}

.modal-header {
  padding-bottom: 0.25rem !important;
}

.form-group {
  margin-bottom: 0.8rem !important;
}

.modal-footer {
  padding-top: 0.25rem !important;
}

.input-valid {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #be2d2d;
}

ng-select.ng-invalid .ng-select-container {
  border-color: red;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 2px #fde6e8;
}

.ng-invalid:not(form):not(div):not(tbody):not(ng-select):not(app-datepicker) {
  border: 1px solid red;
  /* red */
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 2px #fde6e8;
}

.swal2-container {
  z-index: 7777 !important;
}

.p-inputtext {
  display: block;
  width: 100%;
  height: calc(1.47em + 1rem + 2px);
  padding: 0.5rem 0.875rem;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.47;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  :enabled:focus {
    box-shadow: none;
    border: 1px solid #495057;
  }
}

.p-inputtext:enabled:focus {
  box-shadow: none;
  border: 1px solid #495057;
}

.p-inputtext:enabled:hover {
  border: 1px solid #495057;
}

.pac-container {
  background-color: #fff;
  position: absolute !important;
  z-index: 2200;
  border-radius: 2px;
  border-top: 1px solid #d9d9d9;
  font-family: "Sarabun", sans-serif;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
}

.page-content {

  font-family: "Sarabun", "sans-serif" !important;

  .text-black {
    color: #000;
  }
}

.modal-content {

  font-family: "Sarabun", "sans-serif" !important;


}

.pac-logo:after {
  content: "";
  padding: 1px 1px 1px 0;
  height: 16px;
  text-align: right;
  display: block;
  background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/powered-by-google-on-white3.png);
  background-position: right;
  background-repeat: no-repeat;
  background-size: 120px 14px;
}

.hdpi.pac-logo:after {
  background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/powered-by-google-on-white3_hdpi.png);
}

.pac-item {
  cursor: default;
  padding: 0 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 30px;
  text-align: left;
  border-top: 1px solid #e6e6e6;
  font-size: 11px;
  color: #999;
}

.pac-item:hover {
  background-color: #fafafa;
}

.pac-item-selected,
.pac-item-selected:hover {
  background-color: #ebf2fe;
}

.pac-matched {
  font-weight: 700;
}

.pac-item-query {
  font-size: 13px;
  padding-right: 3px;
  color: #000;
}

.pac-icon {
  width: 15px;
  height: 20px;
  margin-right: 7px;
  margin-top: 6px;
  display: inline-block;
  vertical-align: top;
  background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/autocomplete-icons.png);
  background-size: 34px;
}

.hdpi .pac-icon {
  background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/autocomplete-icons_hdpi.png);
}

.pac-icon-search {
  background-position: -1px -1px;
}

.pac-item-selected .pac-icon-search {
  background-position: -18px -1px;
}

.pac-icon-marker {
  background-position: -1px -161px;
}

.pac-item-selected .pac-icon-marker {
  background-position: -18px -161px;
}

.pac-placeholder {
  color: gray;
}

ngx-dropzone,
.custom-ngx-dropzone {
  height: 180px !important;
  background: #fff0 !important;
  // border: 2px dashed #71738666!important;
  border-color: #71738666 !important;
  border-radius: 5px !important;
  color: #adadad !important;
}

ngx-dropzone,
.custom-dropzone {
  margin: 20px;
}

.custom-dropzone {
  height: 250px;
  background: #f5f5f5;
  color: rgb(97, 97, 97);
  border: 2px dashed rgb(177, 177, 177);
  border-radius: 5px;
  font-size: 20px;
}

.custom-dropzone.ngx-dz-hovered {
  border: 3px dashed rgb(134, 201, 131);
}

.borderd {
  border: solid 1px #e2e2e2;
  border-radius: 3px;
}

.borderd-no-left {
  border: solid 1px #e2e2e2;
  border-radius: 3px;
}

.node {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  transition: all 1000ms ease;
}

.divider {
  height: 40px;
  width: 2px;
  margin-left: 4px;
  transition: all 800ms ease;
}

.bg-light-gray {
  background: #e2e2e2 !important;
  color: #666666;
}

.bg-light-green {
  background: #2bc382 !important;
  color: white;
}

.bg-theme {
  background: #6c757d !important;
  color: white;
}

.bg-black {
  background-color: #000 !important;
}

.tab-content {
  background: white;
  padding: 10px;
}

.delete-icon {
  font-size: 18px;
  color: red;
  cursor: pointer;
}

td .ng-select-height-30 {
  .ng-select-container {
    height: 31px !important;
  }
}

td .ng-select .ng-select-container {
  height: 31px !important;
}

.ng-group {
  width: calc(100% - 250px);
}

.ng-group2 {
  width: calc(100% - 200px);
}

.ng-group-half {
  width: 40%;
}

.table-fixed {
  table-layout: fixed;
}

.over-flow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.over-flow-scroll {
  overflow: scroll;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ng-group-full {
  width: 100%;
}

.ng-group3 {
  width: calc(100% - 50px);
}

// @media only screen and (max-width: 600px) {
//     .ng-group {
//         width: 85%;
//     }
//     .ng-group2 {
//         width: 100%;
//     }
// }
// @media only screen and (max-width: 320px) {
//     .ng-group {
//         width: 80%;
//     }
// }
.fav-icon {
  color: red;
  cursor: pointer;
  font-size: 20px;
}

.badge-license {
  border: solid 2px;
}

.ver-line-dashed {
  border-left: 1px dashed #2bc381;
  border-right: 1px dashed #2bc381;
  z-index: 1;
  width: 1px;
}

.ver-line-solid {
  border-left: 1px solid #2bc381;
  border-right: 1px solid #2bc381;
  z-index: 1;
  width: 1px;
}

.p-relative {
  position: relative;
}

.last-loc {
  border-left: 1px solid #ffffff !important;
  border-right: 1px solid #ffffff !important;
}

.rating-start {
  color: #ffcf3f;
}

.more-img {
  position: absolute;
  bottom: 20px;
  right: 20px;
  color: #fff;
  font-size: 2.5rem;
  text-shadow: -2px -3px 3px #000000;
}

.set-w-100 {
  width: 100px !important;
}

.set-w-150 {
  width: 150px !important;
}

.set-w-200 {
  width: 200px !important;
}

.set-w-250 {
  width: 250px !important;
}

.set-w-300 {
  width: 300px !important;
}

.set-w-350 {
  width: 350px !important;
}

.zoom {
  transition: transform 0.2s;
  z-index: -1;
}

.zoom:hover {
  -ms-transform: scale(5);
  /* IE 9 */
  -webkit-transform: scale(5);
  /* Safari 3-8 */
  transform: scale(5);
}

.zoom:focus {
  -ms-transform: scale(5);
  /* IE 9 */
  -webkit-transform: scale(5);
  /* Safari 3-8 */
  transform: scale(5);
}

.click-zoom input[type="checkbox"] {
  display: none;
}

.click-zoom img {
  transition: transform 0.5s ease;
  cursor: zoom-in;
}

.click-zoom input[type="checkbox"]:checked~img {
  -ms-transform: scale(5);
  /* IE 9 */
  -webkit-transform: scale(5);
  /* Safari 3-8 */
  transform: scale(5);
  cursor: zoom-out;
}

.default-width-button {
  width: 135px;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

/* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';

.stepper-wrapper {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.stepper-item::before {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 20px;
  left: -50%;
  z-index: 2;
}

.stepper-item::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 2;
}

.stepper-item .step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #ccc;
  margin-bottom: 6px;
  color: white;
}

.stepper-item.active {
  font-weight: bold;
}

.stepper-item.completed .step-counter {
  background-color: #4bb543;
}

.stepper-item.completed::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #4bb543;
  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 3;
}

.stepper-item:first-child::before {
  content: none;
}

.stepper-item:last-child::after {
  content: none;
}

.more-360-img {
  position: absolute;
  right: 13px;
  text-shadow: -2px -3px 3px #00000073;
  color: #ffffff;
  font-size: 0.95rem;
  float: right;
  text-shadow: none;
  background-color: #000000a8;
  height: 100%;
  top: 0px;
  // top: 19px;
  padding: 1.5rem;
  cursor: pointer;
}


.ngx-gallery {
  display: inline-block;
}

.ngx-gallery-thumbnails {
  border: unset !important;

  .ngx-gallery-thumbnail.ngx-gallery-active {
    border: 1px solid rgba(0, 0, 0, 0.08) !important;
    border-radius: 4px;
  }

  .ngx-gallery-thumbnail {
    border: 1px solid rgba(0, 0, 0, 0.08) !important;
    border-radius: 4px;
  }
}

.ngx-gallery-remaining-count {
  font-size: 25px !important;
}


.ng-gallery-container {
  display: flex;
  overflow-x: hidden;

  // flex-wrap: wrap;
  img {
    width: 100%;
    height: 80px;
    object-fit: contain;
    padding: 1px 0;
    border: 2px solid rgba(0, 0, 0, 0.08) !important;
    border-radius: 4px;
  }
}

.img-more-techx-basic {
  position: absolute;
  right: 4px;
  text-shadow: -2px -3px 3px #00000073;
  color: #ffffff;
  font-size: 0.95rem;
  float: right;
  text-shadow: none;
  background-color: #000000a8;
  height: 80px;
  top: 28px;
  padding: 1.5rem;
  cursor: pointer;
}

.cdk-global-overlay-wrapper {
  z-index: 10001 !important;
}

.cdk-overlay-backdrop {

  z-index: 10001 !important;
}

.cdk-overlay-pane {
  z-index: 10001 !important;

}

gallery {
  z-index: 10001 !important;

}

.cdk-overlay-container {
  z-index: 10001 !important;
}

.rounded8 {
  border-radius: 8px !important;
}

.p-password-input {
  font-family: "Kanit", "sans-serif" !important
}

.font-family-Kanit {
  font-family: "Kanit", "sans-serif" !important
}

.fs-18 {
  font-size: 18px !important;
}

.fs-22 {
  font-size: 22px !important;
}

.fs-xxxl {
  font-size: 2.00rem !important;
}

.btn-register-shipper-primary {
  color: #212529;
  background-color: #D5DF31;
  border-color: #D5DF31;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}

.btn-register-shipper-primary:hover {
  color: #212529;
  background-color: #c1ca1f;
  border-color: #b6bf1e;
}

.btn-register-shipper-primary:focus,
.btn-register-shipper-primary.focus {
  color: #212529;
  background-color: #c1ca1f;
  border-color: #b6bf1e;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(186, 195, 48, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(186, 195, 48, 0.5);
}

.btn-register-carrier-primary {
  color: #fff;
  background-color: #258EA6;
  border-color: #258EA6;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}

.btn-register-carrier-primary:hover {
  color: #fff;
  background-color: #1e7387;
  border-color: #1c6a7c;
}

.btn-register-carrier-primary:focus,
.btn-register-carrier-primary.focus {
  color: #fff;
  background-color: #1e7387;
  border-color: #1c6a7c;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(70, 159, 179, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(70, 159, 179, 0.5);
}

//custom intro js

.introjs-tooltip {
  // min-width: 950px !important; // change to desired
  // max-width: 100% !important; // change to desired
  max-width: none !important;
  width: -moz-min-content !important;
  /* Firefox */
  width: -webkit-min-content !important;
  /* Chrome */
  width: fit-content !important;

}

.introjs-tooltiptext {
  // max-width: 100% !important; // change to desired

  width: -moz-min-content !important;
  /* Firefox */
  width: -webkit-min-content !important;
  /* Chrome */
  width: fit-content !important;
}

.introjs-tooltiptext img {
  width: 50vw;
}


/* Custom CSS FOR TMS more than 2022-03-01 */
.btn-success,
.bg-success {
  color: #000;
  background-color: #32FF98 !important;
  border-color: #32FF98;
}

.btn-success:hover {
  color: #000;
  background-color: #25e987 !important;
  border-color: #25e987;
  // font-weight: 600;
}

.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show>.btn-success.dropdown-toggle {
  color: #000;
  background-color: #25e987;
  border-color: #25e987;
  // font-weight: 600;
}

.btn-success:focus,
.btn-success.focus {
  color: #000;
}

.btn-success-32FF98,
.bg-success-32FF98 {
  color: #000;
  background-color: #32FF98 !important;
  border-color: #32FF98;
}


.btn-danger {
  background-color: #EB5757;
  border-color: #EB5757;
}

.bg-danger {
  background-color: #EB5757 !important;
  color: #fff;
}

.nav-tabs .nav-item .nav-link.active:not(:hover) {
  color: #000;
  border-bottom: 3px solid #32FF98;
}

.iframe-full-page {
  width: 100%;
  height: calc(100vh - 70px);
  overflow-y: scroll;
  border: none !important;
}

/* You can add global styles to this file, and also import other style files */

.form-group {
    > label.required {
      &::after {
        content: " *";
        color: var(--red);
      }
    }

    .required {
      &::after {
        content: " *";
        color: var(--red);
      }
    }
  }

  .h-54px {
    height: 54px;
  }

  .pa-20px {
    padding: 20px;
  }

  ng-select.form-control {
    padding: 0px;

    .ng-select-container {
      border: none;
    }
  }

  .br-style {
    height: 1px;
    background-image: linear-gradient(
      to right,
      #e8e8e8 70%,
      rgba(255, 255, 255, 0) 30%
    );
    background-position: bottom;
    background-size: 10px 10px;
    background-repeat: repeat-x;
    margin: 12px 0px;
  }

  .card-container {
    background-color: #ffffff;
    border-radius: 12px;
    margin-top: 16px;
    padding: 24px 20px;
  }

  .card-body--border-bottom {
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  }

  .collapse-container {
    border-radius: 12px;

    .collapse-header {
      min-height: 54px;
      background-color: #e8e8e8;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 20px;
      transition: all 0.15s ease;
      transition-delay: 0.2s;

      &.collapse-header-close {
        border-radius: 12px;
      }

      i {
        font-size: 20px;
        cursor: pointer;

        &::before {
          font-weight: bold !important;
        }
      }
    }
  }

.create-booking-eh-secondary{
  background-color: #e8e8e8;
}

.linked-text {
  color: #3787ff;
  cursor: pointer;
  text-decoration: underline;
}

.popover-min-w-600 {
  min-width: 600px !important;
  width: 600px !important;
}
